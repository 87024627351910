<template>
  <cs-panel title="用户评价" class="com-product-UserEvaluation">
    <div ref="CommentPanel" class="comment-panel">
      <div
        class="comment-info"
        v-for="(item, index) in source.comment"
        :key="index"
      >
        <a-avatar
          :src="item.avatar"
          class="bg-white"
          style="
            height: 24px;
            width: 24px;
            margin-top: -3px;
            margin-right: 12px;
            border: 1px solid #eee;
          "
        />
        <span style="color: #949898">{{ item.name | encryText }}</span>
        <div class="comment-content">
          <span>“</span>
          <span style="color: #202121" class="text-wrap line-clamp-2">{{
            item.content
          }}</span>
        </div>
      </div>
    </div>
    <div style="text-align: right; height: 35px">
      <span class="left-icon" @click="leftClick">
        <cs-icon
          name="icon-tijiaodingdan_zuofanye"
          type="symbol"
          style="height: 36px; width: 36px"
        />
        <cs-icon
          name="icon-a-tijiaodingdan_zuofanyehover"
          type="symbol"
          style="height: 36px; width: 36px"
        />
      </span>
      <span class="right-icon" @click="rightClick">
        <cs-icon
          name="icon-tijiaodingdan_youfanye"
          type="symbol"
          style="height: 36px; width: 36px"
        />
        <cs-icon
          name="icon-a-tijiaodingdan_youfanyehover"
          type="symbol"
          style="height: 36px; width: 36px"
        />
      </span>
    </div>
  </cs-panel>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      source: {
        comment: [],
      },
      last: -1,
    };
  },
  mounted() {
    this.initData();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async initData() {
      const res =
        await this.api.service.fpc_oapi_evaluate_selectEvaluatePublished(
          {},
          {}
        );
      await this.api.toast(res, 0);
      this.source.comment = res.data.map((ele) => {
        let avatar = ele.headUrl;
        if (!avatar) {
          avatar = this.api.getPublicFile(
            `img/avatar/${Math.round(Math.random() * 8)}.png`
          );
        }
        return {
          avatar,
          name: ele.userRealName,
          content: ele.content,
        };
      });
      this.timer = setInterval(() => {
        this.rightClick();
      }, 5000);
    },
    leftClick() {
      this.$refs.CommentPanel.scrollLeft -= 1108;
      this.last = -1;
    },
    rightClick() {
      this.$refs.CommentPanel.scrollLeft += 1108;
      let last = this.$refs.CommentPanel.scrollLeft / 1108;
      if (last == this.last) {
        last = -1;
        this.$refs.CommentPanel.scrollLeft = 0;
      }
      this.last = last;
    },
  },
};
</script>
<style lang="less" scoped>
.com-product-UserEvaluation {
  /deep/ .cs-panel-body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .left-icon {
    margin-right: 10px;
  }
  .left-icon,
  .right-icon {
    user-select: none;
    cursor: pointer;
    svg:nth-child(1) {
      display: inline-block;
    }
    svg:nth-child(2) {
      display: none;
    }
    &:hover {
      svg:nth-child(1) {
        display: none;
      }
      svg:nth-child(2) {
        display: inline-block;
      }
    }
  }
  .comment-panel {
    width: 1108px;
    overflow: hidden;
    display: -webkit-box;
  }
  .comment-info {
    padding: 30px;
    width: 534px;
    height: 145px;
    margin-right: 20px;
    background: #eff2f5 url(../../assets/user_evaluation_bg@2x.png) no-repeat
      100% / cover;
    .comment-content {
      display: flex;
      span:nth-child(1) {
        font-size: 60px;
        font-family: "经典雅黑";
        height: 10px;
        width: 36px;
        color: #d3d4d8;
        margin-left: -17px;
      }
      span:nth-child(2) {
        margin-left: 17px;
        margin-top: 10px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #202121;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }
}
</style>